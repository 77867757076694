<template>
    <div class="limiter">
        <v-app>
            <div class="container-login100">
                <div class="left-banner">
                    <div class="logo-img">
                        <img src="../../../assets/logo.png" alt="">
                    </div>
                    <h3>服务至上&nbsp;&nbsp;知行合一</h3>
                    <h5>为品牌创造价值</h5>
                    <h5>做互联网内容运营行业标杆</h5>
                </div>
                <div class="wrap-login100">
                    <form class="login100-form validate-form">
                        <img src="../../../assets/logo.png" alt="" class="logo-img">
                        <span class="login100-form-title p-b-26"> 叁柒AI实验室 </span>
                        <v-form v-model="valid" ref="form" class="px-3">
                            <v-text-field label="账号" prepend-inner-icon="mdi-account" v-model="form.account"
                                :rules="[rules.account]" @keyup.enter.native="login" color="#EE8C29"></v-text-field>
                            <v-text-field label="密码" type="password" prepend-inner-icon="mdi-lock-outline"
                                v-model="form.password" :rules="[rules.password]" @keyup.enter.native="login"
                                color="#EE8C29"></v-text-field>
                        </v-form>
                        <div class="container-login100-form-btn">
                            <div class="wrap-login100-form-btn">
                                <div class="login100-form-bgbtn"></div>
                                <v-btn width="100%" color="#02AFF1" :loading="loginLoading" @click="login">
                                    登录
                                </v-btn>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </v-app>
    </div>
</template>
<script>
export default {
    data() {
        return {
            valid: true,
            loginLoading: false,
            rules: {
                account: value => !!value || '账号不能为空',
                password: value => !!value || '密码不能为空',
            },
            form: {
                account: '',
                password: ''
            }
        }
    },
    methods: {
        async login() {
            if (this.$refs.form.validate()) {
                this.loginLoading = true
                let params = {
                    userName: this.form.account,
                    userPwd: this.form.password
                }
                this.$store.dispatch('LoginByUsername', params).then((res) => {
                    this.loginLoading = false
                    this.$router.push({ path: '/' })
                }).catch((res) => {
                    this.loginLoading = false
                })
            }
        }
    },
}
</script>
<style lang="scss" scoped>
@import './css/main.css';

.container-login100 {
    background: url('../../../assets/login_bg.jpg') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .left-banner {
        color: #fff;

        .logo-img {
            display: inline-block;
            padding: 15px 20px 8px;
            background-color: #fff;
            border-radius: 8px;
            margin: 0 0 35px;
        }

        h3 {
            font-size: 68px;
            margin: 10px 0;
        }

        h5 {
            font-size: 36px;
            margin: 10px 0;
        }
    }

    .wrap-login100 {
        padding: 40px !important;
        text-align: center;

        .logo-img {
            margin: 0 auto 40px;
        }
    }

    .login100-form-title {
        margin-bottom: 4rem;
        color: #ef7e1d;
        font-size: 30px;
    }

    .container-login100-form-btn {
        margin-top: 1rem;
    }
}

.v-btn {
    background-color: transparent !important;
    margin-bottom: 0 !important;
    height: 50px !important;
    font-size: 15px;
}

@media only screen and (max-width: 900px) {
    .left-banner{
        display: none;
    }

    .container-login100{
        background-size: 100% 31%;
    }
}
</style>